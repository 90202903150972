import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import PageHero from '../components/PageHero'
import { ImHome } from "react-icons/im"
import { graphql, useStaticQuery, Link } from "gatsby"

const PrivacyPage = (props) => {
    const {privacypage} = useStaticQuery(graphql`
    query {
        privacypage: file(relativePath: {eq: "page/privacy-policy/index.md"}) {
          childMarkdownRemark {
            html
            frontmatter {
              date(formatString: "DD MMMM, YYYY")
              datemod(formatString: "DD MMMM, YYYY")
              description
              featureimagealt
              seometadesc
              seotitle
              title
              featureimage {
                childImageSharp {
                  fluid(maxWidth: 768, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
                extension
                publicURL
              }
            }
          }
        }
      }      
    `)
    const privacydata = privacypage.childMarkdownRemark
    const frontmatter = privacydata.frontmatter
    return (
        <>
            <Layout location={props.location}>
                <SEO title={frontmatter.seotitle} description={frontmatter.seometadesc} image={frontmatter.featureimage.publicURL} />{/* title, description, image, article */}
                <PageHero heading={frontmatter.title} />
                <section className="container mx-auto max-w-7xl text-center pt-4">
                    <div className="text-left px-5 md:px-10 pt-4 pb-4">
                        <ul className="text-left font-mono text-xs md:text-base">
                          <li className="inline-block border-black border-r-2">
                            <Link to="/">
                                <span className="inline-block pr-2"><ImHome /></span>
                                <span className="px-2">Home</span>
                            </Link>
                          </li>
                          <li className="inline-block">
                            <span className="px-2">{frontmatter.title}</span>
                          </li>
                        </ul>
                        <p className="pt-6">Last Updated: {frontmatter.datemod}</p>
                    </div>
                    <div className="cmscontent docs px-5 md:px-10 text-justify" dangerouslySetInnerHTML={{ __html: privacydata.html}} />
                </section>
            </Layout>
        </>
    )
}

export default PrivacyPage