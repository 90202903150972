import React from 'react'
import greenSunburstBg from "../images/green-sunburst-background.svg"

const PageHero = ({heading}) => {
    return(
        <>
            <div className="h-full relative">
                <div className="container mx-auto text-center">
                    <div className="absolute top-0 bottom-0 right-0 left-0 h-full w-full origin-top-left transform -skew-y-3 bg-green-300" style={{backgroundImage: "url(" + greenSunburstBg + ")", backgroundSize: 'cover', overflow: 'hidden'}}></div>
                    <div className="flex flex-col sm:flex-row sm:items-center">
                        <div className="flex-1 z-20 pt-20 pb-14 xl:pb-20 px-8">
                            {heading===undefined?
                            ""
                            :
                            <h1 className="pulserev heading-box-text1 text-3xl sm:text-3xl md:text-4xl lg:text-5xl"><span>{heading}</span></h1>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PageHero